<template>
  <v-list expand nav v-bind="$attrs" v-on="$listeners">
    <template v-for="(item, i) in items">
      <template v-if="check_role(item)">
        <default-list-group
          v-if="item.items"
          :key="`group-${i}`"
          :item="item"
        />

        <default-list-item
          v-else-if="check_role(item)"
          :key="`item-${i}`"
          :item="item"
        />
      </template>
    </template>
  </v-list>
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "DefaultList",
  created() {
    console.log(this.user);
  },
  computed: {
    ...get("user", {
      user: "user"
    })
  },
  components: {
    DefaultListGroup: () => import("./ListGroup"),
    DefaultListItem: () => import("./ListItem")
  },
  methods: {
    check_role(item) {
      if (item.roles) {
        if (item.roles?.allow) {
          return item.roles.allow.some(role => this.user.role == role);
        } else if (item.roles?.reject) {
          return !item.roles.reject.some(role => this.user.role == role);
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
};
</script>
